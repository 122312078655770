<template>
  <v-container fluid>
    <v-card>
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'card-header']">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ this.$t('analytic.lang_salesbyType') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">
          <b-dropdown no-caret right toggle-class="btn-icon btn-icon-only" :menu-class="[this.$vuetify.theme.dark? 'dark-bg' : '']" variant="link">
            <span slot="button-content"><i class="pe-7s-menu btn-icon-wrapper"></i></span>
            <div>
              <h6 class="dropdown-header" tabindex="-1">
                {{ $t('generic.lang_Options') }}</h6>
              <button @click="exportPDF" :class="[this.$vuetify.theme.dark? 'white--text' : '', 'dropdown-item']" tabindex="0" type="button"
              >
                <v-icon class="dropdown-icon" color="warning">picture_as_pdf</v-icon>
                <span class="text-capitalize">{{ $t('generic.lang_export') }} PDF</span>
              </button>

              <button :class="[this.$vuetify.theme.dark? 'white--text' : '', 'dropdown-item']" tabindex="0" type="button" @click="exportExcel">
                <font-awesome-icon class="dropdown-icon success--text"
                                   icon="file-excel"/>
                <span>Excel</span>
              </button>
            </div>
          </b-dropdown>
        </div>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row align="center" justify="center">

          <v-col class="pb-0 mb-0" cols="12" sm="6" md="6" lg="6" xl="6">
            <v-select :items="this.dateOptions" @change="loadData()" class="mx-auto" dense item-text="name"
                      item-value="id" outlined
                      v-model="model"/>
          </v-col>

          <v-col class="pb-0 mb-0" cols="12" sm="6" md="6" lg="6" xl="6">
            <v-select :items="cashierIDs" :label="$t('accounting.lang_reportForCashierID')" class="mx-auto" dense
                      outlined
                      v-model="currentCashierID" multiple/>
          </v-col>

          <v-col class="mt-0 pt-0" cols="12" sm="6" v-if="this.model==9">
            <v-text-field dense :label="$t('generic.lang_from')" outlined type="date" v-model="start"/>
          </v-col>

          <v-col class="mt-0 pt-0" cols="12" sm="6" v-if="this.model==9">
            <v-text-field dense :label="$t('generic.lang_till')" outlined type="date" v-model="end"/>
          </v-col>
          <v-col class="pt-0 ma-0" cols="12">
            <v-checkbox :value="true" :label="$t('generic.lang_compareWith')" v-model="isCompare"/>
          </v-col>

          <v-col class="mt-0 pt-0" cols="12" sm="6" v-if="this.isCompare">
            <v-text-field dense :label="$t('generic.lang_from')" outlined type="date" v-model="comparedStart"/>
          </v-col>

          <v-col class="mt-0 pt-0" cols="12" sm="6" v-if="this.isCompare">
            <v-text-field dense :label="$t('generic.lang_till')" outlined type="date" v-model="comparedEnd"/>
          </v-col>
          <v-col class="mt-0 pt-0" cols="12">
            <v-btn :disabled="this.loading" :loading="this.loading" @click="showChart" block
                   class="bg-primary text-light mx-auto">
              <v-icon class="ma-1">remove_red_eye</v-icon>
              {{ this.$t('generic.lang_display') }}
            </v-btn>
          </v-col>
          <v-col class="pa-0 ma-0" cols="12">
            <v-divider class="pa-0 ma-0"/>
          </v-col>
          <v-col cols="12" sm="8" md="6" lg="6" xl="4" class="mx-auto">
            <v-card v-if="showTopChart">
              <v-card-text class="pa-0">
                <apexchart :options="this.options" :series="this.series" type="donut" width="100%"
                           v-if="showTopChart"></apexchart>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8" md="6" lg="6" xl="4" class="mx-auto">
            <v-card v-if="showTopChart">
              <v-card-text class="pa-0">
                <apexchart :options="this.options" :series="this.series" type="donut" width="100%"
                           v-if="showTopChart"></apexchart>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <apexchart :options="this.comparedOptions" :series="this.comparedSeries" type="bar" width="100%"
                       v-if="showComparedChart && isCompare"></apexchart>
          </v-col>
          <v-col cols="12">
            <v-card v-if="showTable">
              <v-card-text class="pa-0">
                <v-data-table
                    :items="dataToShow"
                    :headers="dataTableHeaders"
                >
                  <template v-slot:item.price="{item}">
                    {{item.price|currency}}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import VueApexCharts from 'vue-apexcharts'
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFileExcel,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {ENDPOINTS} from "@/config";
import moment from "moment";
import {mapState} from "vuex";
import {Events} from "@/plugins/events";
import FileSaver from 'file-saver';

library.add(
    faFileExcel
);

var self = null;

export default {
  name: "CategoriesComponent",
  components: {
    'apexchart': VueApexCharts,
    DatePicker,
    'font-awesome-icon': FontAwesomeIcon,
  },
  data() {
    return {
      isCompare: false,
      start: "",
      end: "",
      comparedStart: "",
      comparedEnd: "",
      model: 9,
      currentCashierID: [],
      comparedOptions: {
        chart: {
          zoom: {
            enabled: true,
            type: 'y',
          },
          background: "#f6f8f9",
          type: 'bar',
          height: 0,
          width: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        xaxis: {
          categories: [],
        },
      },
      options: {
        plotOptions: {
          pie: {
            size: 30,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t('generic.lang_total')
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2) + "%(" + Math.ceil((val / 100) * self.total) + ")";
          },
        },
        chart: {
          type: 'donut',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      series: null,
      comparedSeries: [
        {
          data: [],
          name: ""
        },
        {
          data: [],
          name: ""
        },
      ],
      loading: false,
      total: 0,
      showTable: false,
      showComparedChart: false,
      showTopChart: false,
      dataToShow: [],
      dataTableHeaders: [
        {
          text: this.$t('generic.lang_name'),
          value: "name",
        },
        {
          text: this.$t('erp.lang_QTY'),
          value: "qty",
        },
        {
          text: this.$t('erp.lang_ware_brut'),
          value: "price",
        },
      ],
      excelColumns: [
        {
          label: this.$t('generic.lang_name'),
          field: "name",
        },
        {
          label: this.$t('erp.lang_QTY'),
          field: "qty",
        },
        {
          label: this.$t('erp.lang_ware_brut'),
          field: "price",
        },
      ]
    }
  },
  computed: {
    dateOptions() {
      return [
        {
          id: 1,
          name: this.$t('generic.lang_today'),
        },
        {
          id: 2,
          name: this.$t('generic.lang_yesterday'),
        },
        {
          id: 3,
          name: this.$t('generic.lang_thisWeek'),
        },
        {
          id: 4,
          name: this.$t('generic.lang_last_week'),
        },
        {
          id: 5,
          name: this.$t('generic.lang_this_month'),
        },
        {
          id: 6,
          name: this.$t('generic.lang_last_month'),
        },
        {
          id: 7,
          name: this.$t('generic.lang_this_year'),
        },
        {
          id: 8,
          name: this.$t('generic.lang_last_year'),
        },
        {
          id: 9,
          name: this.$t('generic.lang_from_to'),
        },
      ]
    },
    ...mapState("cashierIDs", {"cashierIDs": state => state.availableCashierIDs}),
    ...mapState("api/auth", {"currentID": state => state.cashierID}),
  },
  methods: {
    showChart() {
      this.loading = true;
      this.showTable = false;
      this.showTopChart = false;
      this.showComparedChart = false;
      this.series = null;
      this.total = 0;
      this.options.labels = [];

      if (this.isCompare) {
        this.comparedChart();
        return;
      }

      this.axios.post(ENDPOINTS.ANALYTICS.WARE.CATEGORIES, this.getForm()).then((res) => {
        if (res.data.success == true) {
          if (res.data.data.length > 0) {
            this.prepareDataTable(res.data.data)
            this.series = res.data.data.map((elt) => parseInt(elt.itemQuantity));
            this.options.labels = res.data.data.map((elt) => {
              this.total += parseInt(elt.itemQuantity);
              return this.getCategoryName(elt.gastroFoodType);
            });
            this.showTopChart = true;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_nothingtoshow'),
              color: "warning"
            });
          }
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    comparedChart() {
      this.showTable = false;
      let data = null;

      this.axios.post(ENDPOINTS.ANALYTICS.WARE.CATEGORIES, this.getForm()).then((res) => {
        if (res.data.success == true) {
          data = res.data.data;
          return this.axios.post(ENDPOINTS.ANALYTICS.WARE.CATEGORIES, {
            startDate: this.getComparedFrom(),
            endDate: this.getComparedTo(),
            cashierID: this.currentCashierID,
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).then(resp => {
        if (resp.data.success) {
          let dataToCompareWith = resp.data.data;
          let categories = [], data_1 = [], data_2 = [];
          let series_1 = [], series_2 = [];
          let ids = [...new Set([...data.map(el => el.gastroFoodType),
            ...dataToCompareWith.map(el => el.gastroFoodType)])]

          ids.forEach(id => {

            data_1 = data.filter(el => el.gastroFoodType === id);
            data_2 = dataToCompareWith.filter(el => el.gastroFoodType === id);

            series_1.push((data_1.length > 0) ? parseInt(data_1[0].itemQuantity) : 0);
            series_2.push((data_2.length > 0) ? parseInt(data_2[0].itemQuantity) : 0);


            if (data_1.length > 0) {
              categories.push(this.getCategoryName(data_1[0].gastroFoodType));
            } else {
              categories.push(this.getCategoryName(data_2[0].gastroFoodType));
            }

          });

          this.comparedSeries[0].data = series_1;
          this.comparedSeries[0].name = (this.model === 9) ?
              this.$t('generic.lang_from') + " " + moment.unix(this.getFrom()).format("DD-MM-YYYY")
              + " " + this.$t('generic.lang_to') + " " + moment.unix(this.getTo()).format("DD-MM-YYYY")
              : this.dateOptions.filter(el => el.id === this.model)[0].name;
          this.comparedSeries[1].data = series_2;
          this.comparedSeries[1].name = this.$t('generic.lang_from') + " " + moment.unix(this.getComparedFrom()).format("DD-MM-YYYY")
              + " " + this.$t('generic.lang_to') + " " + moment.unix(this.getComparedTo()).format("DD-MM-YYYY")
          this.comparedOptions.xaxis.categories = categories;
          this.comparedOptions.chart.height = categories.length * 36 + "";
          this.showComparedChart = true;


        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    prepareDataTable(data) {
      this.showTable = false;
      let dataToRender = [], row = [];
      data.forEach((el, i) => {
        row = {};
        row['name'] = this.getCategoryName(el.gastroFoodType);
        row['qty'] = parseInt(el.itemQuantity);
        row['price'] = Number(el.itemTotal);

        dataToRender.push(row);
      })
      this.showTable = true;
      this.dataToShow = dataToRender;
    },
    getForm() {
      let form = {};
      let now = Math.floor(Date.now() / 1000);
      let cashierID = this.currentCashierID;

      if (this.model == 1)
        form = {
          startDate: moment.unix(now).startOf("days").unix(),
          endDate: now,

        }
      else if (this.model == 2)
        form = {
          startDate: moment.unix(now).startOf("days").subtract(1, "days").unix(),
          endDate: moment.unix(now).startOf("days").subtract(1, "seconds").unix(),

        }
      else if (this.model == 3)
        form = {
          startDate: this.getThisWeek(),
          endDate: now,

        }
      else if (this.model == 4)
        form = {
          startDate: this.getLastWeek(),
          endDate: moment.unix(this.getLastWeek()).endOf("weeks").unix(),

        }
      else if (this.model == 5)
        form = {
          startDate: this.getThisMonth(),
          endDate: now,
        }
      else if (this.model == 6) {
        form = {
          startDate: this.getLastMonth(),
          endDate: moment.unix(this.getLastMonth()).endOf("months").unix(),

        }
      } else if (this.model == 7)
        form = {
          startDate: this.getThisYear(),
          endDate: now,

        }
      else if (this.model == 8) {
        form = {
          startDate: this.getLastYear(),
          endDate: moment.unix(this.getLastYear()).endOf("years").unix(),

        }
      } else if (this.model == 9) {
        form = {
          startDate: this.getFrom(),
          endDate: this.getTo(),

        }
      }
      form.cashierID = cashierID;
      return form;
    },
    generatePDF() {
    },
    loadData() {
      //////console.log(moment.unix(this.getForm().startDate)._d,moment.unix(this.getForm().endDate)._d)
    },
    getComparedFrom() {
      if (this.isCompare) {
        let startDate = new Date(this.comparedStart);
        return moment.unix(startDate.getTime() / 1000).startOf("days").unix();
      } else
        return moment.unix(Date.now() / 1000).startOf("days").unix();
    },
    getComparedTo() {
      if (this.isCompare) {
        let endDate = new Date(this.comparedEnd);
        return moment.unix(endDate.getTime() / 1000).endOf("days").unix();
      } else
        return moment.unix(Date.now() / 1000).endOf("days").unix();
    },
    getFrom() {
      let startDate = new Date(this.start);
      return moment.unix(startDate.getTime() / 1000).startOf("days").unix();
    },
    getTo() {
      let endDate = new Date(this.end);
      return moment.unix(endDate.getTime() / 1000).endOf("days").unix();
    },
    getLastYear() {
      let current = moment.unix(Date.now() / 1000)

      return current.startOf("years").subtract(1, 'years').unix();
    },
    getThisYear() {
      let current = moment.unix(Date.now() / 1000);

      return current.startOf("years").unix();
    },
    getLastMonth() {
      let current = moment.unix(Date.now() / 1000);
      return current.startOf("months").subtract(1, "months").unix();
    },
    getThisMonth() {
      let current = moment.unix(Date.now() / 1000);
      return current.startOf("months").unix();
    },
    getLastWeek() {
      let current = moment.unix(Date.now() / 1000);

      return current.startOf("weeks").subtract(1, "weeks").unix()
    },
    getThisWeek() {
      let current = moment.unix(Date.now() / 1000);
      return current.startOf("weeks").unix();
    },
    getCategoryName(id) {
      if (id === 1) {
        return this.$t('generic.lang_beverage');
      } else if (id === 2) {
        return this.$t('generic.lang_food');
      } else if (id === 4) {
        return this.$t('generic.lang_service');
      } else {
        return this.$t('generic.lang_item');
      }
    },
    exportExcel() {
      this.axios.post(ENDPOINTS.ANALYTICS.WARE.EXPORT.EXCEL.SALESBYTYPE, this.getForm(),
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        //DOWNLOAD pdf
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }), "ByType.xlsx");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + '\n' + err.message,
          color: "error"
        });
      }).finally(() => {

      })
    },
    exportPDF() {
      this.axios.post(ENDPOINTS.ANALYTICS.WARE.EXPORT.PDF.SALESBYTYPE, this.getForm(),
          {
            responseType: 'arraybuffer',
          }).then((res) => {


        //GENERATE PDF CONTENT FOR iFRAME
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        this.iframePDFContent = URL.createObjectURL(blobContent);

        this.showPDF = true;

        //DOWNLOAD pdf
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "Sales by type.pdf");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + '\n' + err.message,
          color: "error"
        });
      }).finally(() => {

      })
    },
  },
  mounted() {
    self = this;
    this.currentCashierID.push(this.currentID);
  }
}
</script>

<style>
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  top: 6px !important;
}
</style>
